(function($) {
	'use strict';

	$(document).ready(function() {
        init();
	});

	var init = function()
    {
        $('.searchPage .select2, .ecoPage #step-2 .select2').select2({
            placeholder: 'Renseigner une gare',
            language: {
                noResults: function(){
                    return 'Sélectionnez une ville du réseau TER Breizh Go';
                }
            },
            matcher: (params, data) => {
                params.term = params.term.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

                // If there are no search terms, return all of the data
                if ($.trim(params.term) === '') {
                    return data;
                }
            
                // Do not display the item if there is no 'text' property
                if (typeof data.text === 'undefined') {
                    return null;
                }

                // ici
                let term = params.term.toUpperCase()
                if (term.includes('ST')) {
                    if ( data.text.indexOf(term.replace(/ ST /i, ' SAINT ')) > -1 || data.text.indexOf(term.replace(/^ST /i, 'SAINT ')) > -1 || data.text.indexOf(term.replace(/ ST$/i, ' SAINT')) > -1 || data.text.indexOf(term.replace(/^ST$/i, 'SAINT')) > -1 || data.text.indexOf(term) > -1) {
                        return data;
                    }
                }else if(term.includes('SAINT')){
                    if (data.text.indexOf(term.replace(/ SAINT /i, ' ST ')) > -1 || data.text.indexOf(term.replace(/^SAINT /i, 'ST ')) > -1 || data.text.indexOf(term.replace(/ SAINT$/i, ' ST')) > -1 || data.text.indexOf(term.replace(/^SAINT$/i, 'ST')) > -1 || data.text.indexOf(term) > -1 ) {
                        return data;
                    }
                }
            
                // `params.term` should be the term that is used for searching
                // `data.text` is the text that is displayed for the data object
                if (data.text.indexOf(params.term.toUpperCase()) > -1) {
                    return data;
                }
            
                // Return `null` if the term should not be displayed
                return null;
            }
        });
	};

})(jQuery);