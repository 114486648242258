(function($) {
	'use strict';

    $(document).ready(function() {
        init();
	});

	let init = function()
    {
        initSliders();
        window.addEventListener('resize', initSliders);
	};

	window.initSliders = function()
	{
		initSliders();
	}

    let initSliders = function ()
    {
        for (const element of document.getElementsByClassName('swiper'))
		{
			if (  ( element.classList.contains('swiper-mobile-only') && window.innerWidth < 768 )
				|| !element.classList.contains('swiper-mobile-only')
			) {

				if (element.swiper == null) {
					new Swiper('#' + element.id, {
						slidesPerView: 'auto',
					});
				}

			} else if (element.swiper != null) {
				element.swiper.destroy();
			}
		}
    }

})(jQuery);