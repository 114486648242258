(function($) {
	'use strict';

    $(document).ready(function() {
       init();
	});

	var init = function()
    {
        // Liste des déclencheurs de popup lors de la validation
        const popup_triggers = document.querySelectorAll('*[data-popup]');

        if(!popup_triggers.length) {
            return;
        }

        popup_triggers.forEach(trigger =>
        {
            const popup = document.querySelector('dialog#popup-' + trigger.dataset.popup);
            trigger.removeAttribute('data-popup');

            trigger.addEventListener('click', (event) => {
                popup.showModal();

                 // L'évenement d'ouverture d'une popup "dialog" n'existe pas, on l'ajoute
                popup.dispatchEvent(new Event('open'));
            });

            popup.querySelector('.formPopup__title__close').addEventListener('click', (event) => {
                popup.close();
            });
        });
	};

    /*
    document.addEventListener('click', (event) =>
    {
        console.log(event.target.tagName);
        if (event.target.tagName == 'DIALOG')
        {
            event.target.close();
        }
    });*/

    document.initPopups = function()
    {
        init();
    }

})(jQuery);