(function($) {
	'use strict';

    $(document).ready(function() {
        if (document.querySelector('.ecoPage')) { init(); }
	});

    /**
     * Initialise le formulaire
     */
	let init = function()
    {
        // Navigation
        watchSteps();

        // Évènements
        addressSwapper();

        // Ajax
        handleSubmit();
        initAjaxSelects();
        fillFuelsCosts();
    };

    /*****************************
     *        NAVIGATION         *
     *****************************/

    let watchSteps = function()
    {
        // Suivi de l'étape en cours
        let step_indicator = document.querySelector('#step-indicator');

        step_indicator.addEventListener('change', (event) =>
        {
            // Si on affiche l'étape des gares proches, on renseigne les valeurs du formulaire
            if (document.querySelector('#step-' + step_indicator.value + ' .stationsStep'))
            {
                fillStations();
            }

            // Si on affiche l'étape du récapitulatif, on renseigne les valeurs du formulaire
            if (document.querySelector('#step-' + step_indicator.value + ' .ecoSearch__summary'))
            {
                document.getElementById('nav-step-7').classList.add('completed');

                // Si le prix du carburant n'est pas renseigné
                if (document.getElementsByName('origin_transport_car-price')[0].value == '')
                {
                    document.getElementsByName('origin_transport_car-fuel')[0].dispatchEvent(new Event('change'));
                }
            }
        });

        let origin_station_input = document.getElementsByName('origin_station')[0];
        let destination_station_input = document.getElementsByName('destination_station')[0];

        $(origin_station_input).on('select2:select', (event) =>
        {
            document.querySelector('#step-2 .preventStep').checked = true;
            document.querySelector('#step-2').classList.remove('error');
            origin_station_input.dispatchEvent(new Event('change'));
        });

        $(destination_station_input).on('select2:select', (event) =>
        {
            document.querySelector('#step-2 .preventStep').checked = true;
            document.querySelector('#step-2').classList.remove('error');
            destination_station_input.dispatchEvent(new Event('change'));
        });
    }

    /**
     * Déclenche un changement d'étape
     * @param {int} step : Numéro de l'étape vers laquelle naviguer
     */
    let navigateTo = function(step)
    {
        // Suivi de l'étape en cours
        let step_indicator = document.querySelector('#step-indicator');

        step_indicator.value = step;
        step_indicator.dispatchEvent(new Event('change'));
    }

    /*****************************
     *        EVENEMENTS         *
     *****************************/

    /**
     * Permet d'inverser les valeurs de deux champs adresse
     * Utilisé à l'étape 1 : Permet d'inverser l'adresse d'origine et de destination du trajet
     */
    let addressSwapper = function()
    {
        let swapper = document.querySelector('.ecoPage #step-1 .search__field__swapper');

        swapper.addEventListener('click', (event) =>
        {
            let source_inputs = {
                address:   document.getElementsByName('origin_address')[0],
                id:        document.getElementsByName('origin_address-id')[0],
                latitude:  document.getElementsByName('origin_address-latitude')[0],
                longitude: document.getElementsByName('origin_address-longitude')[0],
            };
    
            let target_inputs = {
                address:   document.getElementsByName('destination_address')[0],
                id:        document.getElementsByName('destination_address-id')[0],
                latitude:  document.getElementsByName('destination_address-latitude')[0],
                longitude: document.getElementsByName('destination_address-longitude')[0],
            };
    
            let temp_swapper_values = {
                address:   source_inputs.address.value,
                id:        source_inputs.id.value,
                latitude:  source_inputs.latitude.value,
                longitude: source_inputs.longitude.value,
            };

            for (let key in source_inputs)
            {
                source_inputs[key].value = target_inputs[key].value;
                target_inputs[key].value = temp_swapper_values[key];
            }

            source_inputs.address.dispatchEvent(new Event('change'));
            target_inputs.address.dispatchEvent(new Event('change'));
        });
    }

    /*****************************
     *           AJAX            *
     *****************************/

    /**
     * Prépare et exécute une requête
     */
    let sendQuery = function(url, body)
    {
        const headers = new Headers({
            "X-CSRF-TOKEN": document.getElementsByName('_token')[0].value,
        });

        if (typeof body == 'string')
        {
            headers.append("Content-Type","application/json");
        }

        return fetch(url, {
            method: 'POST',
            headers: headers,
            mode: 'cors',
            cache: 'default',
            body: body,
        });
    }
    
    /**
     * Étape 1 - Gestion du remplissage automatique de la recherche d'adresse
     */
    let initAjaxSelects = function()
    {
        let selects = document.querySelectorAll('.ajaxSelect2');

        selects.forEach(select => {
            let name_input      = document.getElementsByName(select.dataset.name)[0];
            let id_input        = document.getElementsByName(select.dataset.name + '-id')[0];
            let latitude_input  = document.getElementsByName(select.dataset.name + '-latitude')[0];
            let longitude_input = document.getElementsByName(select.dataset.name + '-longitude')[0];

            initSelect(select, id_input.value, name_input.value);

            if (id_input.value != '')
            {
                select.nextSibling.classList.add('select2-container--filled');
            }
            
            $(select).on("select2:select", function(event)
            { 
                let select_data = $(select).select2('data');
                let fuel_type_input = document.getElementsByName('origin_transport_car-fuel')[0];

                event.target.nextSibling.classList.toggle('select2-container--filled', (select_data[0].text != ''));
                fuel_type_input.clearCosts();

                sendQuery(
                    // URL
                    select.dataset.addressLocation,
                    // Body
                    JSON.stringify({
                        address: select_data[0].text,
                    })
                )
                .then((response) => response.json())
                .then((data) => {
                    name_input.value      = select_data[0].text;
                    id_input.value        = data.id;
                    latitude_input.value  = data.latitude ?? latitude_input.value;
                    longitude_input.value = data.longitude ?? longitude_input.value;

                    id_input.dispatchEvent(new Event('change'));
                });             
            });

            $(select).on("select2:swap", function(event)
            {
                let select_data = $(select).select2('data');
                let fuel_type_input = document.getElementsByName('origin_transport_car-fuel')[0];

                event.target.nextSibling.classList.toggle('select2-container--filled', (select_data[0].text != ''));
                fuel_type_input.clearCosts();

                name_input.value      = select_data[0].text;
                id_input.value        = select_data[0].id;
                latitude_input.value  = select_data[0].latitude ?? latitude_input.value;
                longitude_input.value = select_data[0].longitude ?? longitude_input.value;

                id_input.dispatchEvent(new Event('change'));
            });

            name_input.addEventListener('change', (event) =>
            {
                // Source : https://select2.org/programmatic-control/add-select-clear-items#create-if-not-exists
                if ($(select).find("option[value='" + id_input.value + "']").length) {
                    $(select).val(id_input.value).trigger('change');
                } else { 
                    // Create a DOM Option and pre-select by default
                    var newOption = new Option(name_input.value, id_input.value, true, true);
                    // Append it to the select
                    $(select).append(newOption).trigger('change');
                } 
                                
                $(select).trigger('select2:swap');
            });
        })
    }

    let initSelect = function(select, id, name)
    {
        $(select).select2({
            containerCssClass: "select2-selection--address",
            dropdownCssClass: "select2-dropdown--address",
            minimumInputLength: 1,
            data: [{
                'id': id,
                'text': name,
            }],
            language: {
                noResults: function() {
                    return 'Choisir une ville de Bretagne ou Pays de Loire'; 
                },
                inputTooShort: function() {
                    return 'Renseigner 1 caractère minimum';
                },
                searching: function () {
                    return 'Recherche...';
                }
            },
            ajax: {
                url: select.dataset.autofill,
                dataType: 'json',
                delay: 500,
                type:'POST',
                data: function (params)
                {
                    document.querySelector('.select2-results__options').classList.add('active');

                    return {
                        address: params.term,
                        _token: document.getElementsByName('_token')[0].value,
                    };
                },
                cache: true
            },
        });
    }

    /**
     * Étape 1 - Remplit la popup de coût des carburants lors du changement de l'adresse d'origine
     */
    let fillFuelsCosts = function()
    {
        let car_popup = document.getElementById('popup-car');
        let fuel_type_input = car_popup.querySelector('[name="origin_transport_car-fuel"]');
        let fuel_cost_input = document.getElementsByName('origin_transport_car-price')[0];

        fuel_type_input.addEventListener('change', (event) =>
        {
            if (typeof fuel_type_input.options[0].dataset.cost == 'undefined')
            {
                car_popup.fillCosts();
            }

            fuel_cost_input.value = event.target.selectedOptions[0].dataset.cost;
        });

        fuel_type_input.clearCosts = function()
        {
            document.getElementsByName('origin_transport_car-price')[0].value = null;

            for (let option of fuel_type_input.options)
            {
                option.removeAttribute('data-cost');
            }
        }

        car_popup.fillCosts = function()
        {
            sendQuery( document.getElementById('popup-car-url').dataset.url )
            .then((response) => response.json())
            .then((data) => {
                for (let option of fuel_type_input.options)
                {
                    option.dataset.cost = data[option.value] ?? 0;
                }

                fuel_type_input.dispatchEvent(new Event('change'));
            });
        }

        car_popup.addEventListener('open', (event) =>
        {
            if (typeof fuel_type_input.options[0].dataset.cost == 'undefined')
            {
                car_popup.fillCosts();
            }
        });

        car_popup.addEventListener('close', (event) => {
            let result_step = document.querySelector('.ecoSearch__result');
            let submit_button = document.querySelector('.ecoSearch__summary__actions__submit');

            if (result_step.innerHTML != '')
            {
                submit_button.click();
            }
        });
    }

    /**
     * Étape 2 - Demande et renseigne les stations TER les plus proches des adresses d'origine et de destination du trajet
     */
    let fillStations = function()
    {
        let origin_station_input = document.getElementsByName('origin_station')[0];
        let destination_station_input = document.getElementsByName('destination_station')[0];

        sendQuery(
            // URL
            document.querySelector('#step-2 .search__step__content').dataset.url,
            // Body
            JSON.stringify({
                'origin_latitude':       document.getElementsByName('origin_address-latitude')[0].value,
                'origin_longitude':      document.getElementsByName('origin_address-longitude')[0].value,
                'destination_latitude':  document.getElementsByName('destination_address-latitude')[0].value,
                'destination_longitude': document.getElementsByName('destination_address-longitude')[0].value,
            })
        )
        .then((response) => response.json())
        .then((data) => {
            origin_station_input.value = data.origin_station.id;
            destination_station_input.value = data.destination_station.id;

            // Si les stations trouvées n'ont pas de route les reliant en BO (distancier)
            // Affiche une erreur pour l'étape et bloque le passage à la suite du formulaire
            if (data.error)
            {
                document.querySelector('#step-2 .preventStep').checked = false;
                document.querySelector('#step-2').classList.add('error');
            }
            else
            {
                document.querySelector('#step-2 .preventStep').checked = true;
                document.querySelector('#step-2').classList.remove('error');
            }

            origin_station_input.dispatchEvent(new Event('change'));
            destination_station_input.dispatchEvent(new Event('change'));
        });
    }

    /**
     * Étape 8 - Se déclenche à la soumission du formulaire et affiche la page de résultats
     */
    let handleSubmit = function()
    {
        let form = document.querySelector('.search__form');
        let fillable_result = document.querySelector('.ecoSearch__result');
        let result_step = document.querySelector('#step-indicator').max;
        let loader = document.querySelector('dialog#popup-loading');

        if (!fillable_result)
        {
            return 0;
        }

        form.addEventListener('submit', (event) =>
        {
            let form_data = new FormData(form);
            let fuel_cost_input = document.getElementsByName('origin_transport_car-price')[0];
            let fuel_fuel_input = document.getElementsByName('origin_transport_car-fuel')[0];
        
            document.getElementsByName('origin_transport_car-fuel')[0].value

            form_data.append('origin_transport_car-type', document.getElementsByName('origin_transport_car-type')[0].value);
            form_data.append('origin_transport_car-fuel', fuel_fuel_input.options[fuel_fuel_input.selectedIndex].value);
            form_data.append('origin_transport_car-price', fuel_cost_input.value);

            event.preventDefault();
            loader.showModal();

            sendQuery(form.action, form_data)
            .then((response) => response.text())
            .then((data) => {
                // Délai artificiel pour permettre de voir la barre de chargement se remplir
                setTimeout(function()
                {
                    // Rempli la section de résultat avec la vue retournée par le serveur
                    fillable_result.innerHTML = data;

                    // Initialise les EventListeners sur les boutons popup
                    document.initPopups();

                    // Marque les étapes récapitulatif et résultat comme complétée, et affiche la page de résultat 
                    document.querySelector('.search__navigation__step.active').classList.add('completed');
                    navigateTo(result_step);
                    document.querySelector('.search__navigation__step.active').classList.add('completed');

                    // Ferme le loader
                    loader.close()
                }, 1500);                
            });
        });
    }

})(jQuery);