(function($) {
	'use strict';

    $(document).ready(function() {
        init();
	});

    /**
     * Initialise le formulaire
     */
	let init = function()
    {
        // Navigation
        navigation();

        // Validation
        validation();

        // Évènements
        addressSwapper();
        handleReset();
        infoMessageEvent();

        // Ajax
        handleSubmit();

        // Chargement
        resume();
    };

    /**
     * Permet de reprendre le formulaire si des champs sont déjà remplis (rechargement de la page)
     */
    let resume = function()
    {
        let current_step = 0;
        let steps = document.querySelectorAll('.search__step:not(:last-of-type)');

        // Valide les champs de chaque étape, et s'arrête à la première étape dont les champs ne sont pas valides
        Array.from(steps).every((step) => {
            let step_inputs = step.querySelectorAll('input, select');
            let next_action = step.querySelector('.search__step__navigation__next');

            let is_valid = validateInputs(step_inputs);

            if (next_action != null)
            {
                next_action.disabled = !is_valid;
            }

            current_step++;

            return is_valid;
        });

        if (current_step > 1)
        {
            // Affiche le formulaire
            enableForm();

            // Affiche cette étape non terminée
            showStep(current_step);

            // Met à jour le suivi d'étape
            document.querySelector('#step-indicator').value = current_step;
            document.querySelector('#step-indicator').dispatchEvent(new Event('change'));

            // Note les étapes précédentes comme complétées
            for (let step = 1; step < current_step; step++)
            {
                document.querySelector('#nav-step-' + step).classList.add('completed');
            }
        }
    }

    /**
     * Donne accès au formulaire
     */
    let enableForm = function()
    {
        document.querySelector('.search').classList.add('active');
    }

    /*****************************
     *        NAVIGATION         *
     *****************************/

    /**
     * Gestion de la navigation dans le formulaire
     */
    let navigation = function()
    {
        // Suivi de l'étape en cours
        let step_indicator = document.querySelector('#step-indicator');
        
        // Boutons de navigation présents dans le formulaire
        let nav_actions = document.querySelectorAll('.navAction');

        // Boutons "Précédent"
        let prev_actions = document.querySelectorAll('.search__step__navigation__prev');

        // Boutons "Suivant"
        let next_actions = document.querySelectorAll('.search__step__navigation__next'); 

        // Passage à l'étape précédente
        prev_actions.forEach(button =>
        {
            button.addEventListener('click', () =>
            {
                navigateTo(step_indicator.value - 1);
            });
        });

        // Passage à l'étape suivante + noter l'étape actuelle comme "Complétée" dans la barre de navigation
        next_actions.forEach(button =>
        {
            button.addEventListener('click', () =>
            {
                document.querySelector('#nav-step-' + step_indicator.value).classList.add('completed');
                navigateTo(+step_indicator.value + 1);
            });
        });

        // Passage à l'étape sélectionnée
        nav_actions.forEach(button =>
        {
            button.addEventListener('click', () =>
            {
                navigateTo(button.dataset.target);
            });
        });

        // Vérifie que l'étape demandée est correcte et l'affiche
        step_indicator.addEventListener('change', () =>
        {
            // Empêche le dépassement de valeur
            if (step_indicator.value < step_indicator.min)
            {
                document.querySelector('.search__form').reset();
            }
            if (step_indicator.value >= step_indicator.max)
            {
                step_indicator.value = step_indicator.max;
            }

            // Met à jour la barre de navigation et l'étape affichée
            showStep(step_indicator.value);
        });
    }

    /**
     * Affiche l'étape demandée et met à jour la barre de navigation
     * @param {int} step : Valeur de l'étape à afficher
     */
    let showStep = function(step)
    {
        // Affiche le formulaire si on accède à la première étape
        if (step == 1)
        {
            enableForm();
        }

        // Si on affiche l'étape du récapitulatif, on renseigne les valeurs du formulaire
        if (document.querySelector('#step-' + step + ' .ecoSearch__summary') || document.querySelector('#step-' + step + ' .search__summary'))
        {
            fillSummary();
        }

        // Change le formulaire actif
        document.querySelector('.search__step.active')?.classList.remove('active');
        document.querySelector('#step-' + step).classList.add('active');

        // Change l'étape active dans la barre de navigation
        document.querySelector('.search__navigation__step.active')?.classList.remove('active');
        document.querySelector('#nav-step-' + step).classList.add('active');

        // Valide les champs du formulaire pour quelques cas possibles en fonction de la gestion des champs préremplis du navigateur
        // document.querySelector('#step-' + step + ' input')?.dispatchEvent(new Event('change'));
    }

    /**
     * Déclenche un changement d'étape
     * @param {int} step : Numéro de l'étape vers laquelle naviguer
     */
    let navigateTo = function(step)
    {
        // Suivi de l'étape en cours
        let step_indicator = document.querySelector('#step-indicator');

        step_indicator.value = step;
        step_indicator.dispatchEvent(new Event('change'));
    }

    /*****************************
     *        VALIDATION         *
     *****************************/

    /**
     * Valide les champs d'une étape pour autoriser le passage à l'étape suivante
     */
    let validation = function()
    {
        let steps = document.querySelectorAll('.search__step');

        steps.forEach(step =>
        {
            let inputs = step.querySelectorAll('input, select');
            let next_action = step.querySelector('.search__step__navigation__next');

            inputs.forEach(input =>
            {
                $(input).on('change', () => 
                // input.addEventListener('change', () =>
                {
                    next_action.disabled = !validateInputs(inputs);
                });
            });
        });
    }

    /**
     * Valide une liste de champs
     * @param {*} inputs 
     * @returns Si tous les champs sont corrects ou non
     */
    function validateInputs(inputs)
    {
        let is_valid = true;
        inputs.forEach(input =>
        {
            if (!input.validity.valid)
            {
                is_valid = false;
            }
        });

        return is_valid;
    }

    /*****************************
     *        EVENEMENTS         *
     *****************************/

    /**
     * Permet d'inverser les valeurs de deux champs adresse
     * Utilisé à l'étape 1 : Permet d'inverser l'adresse d'origine et de destination du trajet
     */
    let addressSwapper = function()
    {
        let swapper = document.querySelector('.searchPage #step-1 .search__field__swapper');

        if (swapper)
        {
            swapper.addEventListener('click', function(event)
            {
                let origin_station = document.getElementsByName('origin_station')[0];
                let destination_station = document.getElementsByName('destination_station')[0];
    
                let temp_station_value = origin_station.value;
    
                origin_station.value = destination_station.value;
                destination_station.value = temp_station_value;
    
                origin_station.dispatchEvent(new Event('change'));
                destination_station.dispatchEvent(new Event('change'));
            });
        }
    }

    /**
     * Se déclenche à la réinitialisation du formulaire et affiche la première page
     */
    let handleReset = function()
    {
        let resetAction = document.querySelector('.resetAction');
        let form = document.querySelector('.search__form');

        form.addEventListener('reset', (event) => {
            location.reload();
        });

        resetAction?.addEventListener('click', (event) => {
            form.reset();
        });
    }

    /**
     * Actions d'affichage de la page des résultats (Moteur tarifaire uniquement)
     */
    let resultActions = function()
    {
        let products = document.querySelectorAll('.search__result__items > .resultBlock');

        products.forEach((product) =>
        {
            let links_action = product.querySelectorAll('.resultBlock__link:not(.resultBlock__options .resultBlock__link), .resultBlock__options__toggle');
            let infos_action = product.querySelector('.resultBlock__information__toggle');

            // Si le produit propose plusieurs options, on remplace le bouton de consultation par un bouton affichant ces options
            if (product.querySelector('.resultBlock__options'))
            {

                links_action.forEach((action) =>
                { 
                    action.addEventListener('click', (event) =>
                    {
                        event.preventDefault();
                        product.classList.toggle('options-visible');
                    })
                });

            }

            infos_action?.addEventListener('click', (event) =>
            {
                product.classList.toggle('informations-visible');
            });
        });
    }

    /**
     * Remplit les valeurs du récapitulatif
     */
    let fillSummary = function()
    {
        let fillables = document.querySelectorAll('[data-link]');

        fillables.forEach((fillable) => {

            let linked_input = document.querySelector(
                'input[name="' + fillable.dataset.link +'"]:checked,' +
                'input[name="' + fillable.dataset.link +'"][type="text"],' +
                'select[name="' + fillable.dataset.link +'"]'
            );

            // Si l'input est un select, on récupère la valeur de son label à afficher au lieu de l'ID
            let value = (linked_input.nodeName != 'SELECT')
                ? linked_input.value
                : linked_input.options[linked_input.selectedIndex].innerHTML;

            // Si la valeur a afficher est un simple texte ou un bloc complet
            if (!fillable.classList.contains('blockValue'))
            {
                fillable.innerHTML = value;
            }
            else
            {
                fillable.querySelectorAll('div').forEach((element) => element.classList.remove('active'));

                fillable.querySelector('[data-value="' + value + '"]').classList.add('active');
            }
        });

        toggleInfoMessage();

        setTimeout(function() {
            document.getElementById('summary')?.swiper?.update();
        }, 300);               
    }

    /**
     * Affiche ou non le bloc informations dans la page de résultat en fonction de critères constants
     * + 26 ans ET fréquence régulier ou occasionnel
     */
    let toggleInfoMessage = function()
    {
        let infos_block = document.querySelector('.search__result__infos');
        let profile = document.querySelector('input[name="profile"]:checked').value;
        let frequency = document.querySelector('input[name="frequency"]:checked').value;

        if (infos_block)
        {
            infos_block.classList.toggle('active', (profile == 3 && (frequency == 2 || frequency == 4)));
        }
    }

    let infoMessageEvent = function()
    {
        let infos_button = document.querySelector('.search__result__infos__toggle');
        let infos_container = document.querySelector('.search__result__infos');

        if (infos_button)
        {
            infos_button.addEventListener('click', function(event)
            {
                infos_container.classList.toggle('open');
            });
        }
    }

    /**
     * Prépare et exécute une requête
     */
    let sendQuery = function(url, body)
    {
        const headers = new Headers({
            "X-CSRF-TOKEN": document.getElementsByName('_token')[0].value,
        });

        if (typeof body == 'string')
        {
            headers.append("Content-Type","application/json");
        }

        return fetch(url, {
            method: 'POST',
            headers: headers,
            mode: 'cors',
            cache: 'default',
            body: body,
        });
    }

    /**
     * Étape 5 - Se déclenche à la soumission du formulaire et affiche la page de résultats
     */
    let handleSubmit = function()
    {
        let form = document.querySelector('.searchPage .search__form');
        let fillable_result = document.querySelector('.search__result__container');
        let result_step = document.querySelector('#step-indicator').max;
        let loader = document.querySelector('dialog#popup-loading');

        if (!fillable_result)
        {
            return 0;
        }

        form?.addEventListener('submit', (event) =>
        {
            let form_data = new FormData(form);

            event.preventDefault();
            // loader.showModal();

            sendQuery(form.action, form_data)
            .then((response) => response.text())
            .then((data) => {
                // Délai artificiel pour permettre de voir la barre de chargement se remplir
                // setTimeout(function()
                // {
                    // Rempli la section de résultat avec la vue retournée par le serveur
                    fillable_result.innerHTML = data;

                    // Marque les étapes récapitulatif et résultat comme complétée, et affiche la page de résultat 
                    document.querySelector('.search__navigation__step.active').classList.add('completed');
                    navigateTo(result_step);
                    document.querySelector('.search__navigation__step.active').classList.add('completed');

                    resultActions();
                    window.initSliders();
                    // Ferme le loader
                    // loader.close()

                // }, 1500);
            });
        });
    }

})(jQuery);